import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { logClick } from "./amplitude"

import {
  Body,
  breakpoints,
  ChevronRightIcon,
  colours,
  column,
  GridRow,
  Headline2,
  horizontal,
  randomExpertImageLinks,
  TextLink,
  TertiaryLink,
  vertical,
} from "design-kit"

const Container = styled.div`
  color: ${colours.offBlack};
  /* i'm sorry */
  background-image: linear-gradient(
    ${colours.white} 50%,
    ${colours.offBlack} 50%
  );

  ${breakpoints.tablet`
    background-image: linear-gradient(to right, ${colours.white} 50%, ${colours.offBlack} 50%);
  `}
`

const Section = styled.section`
  margin-top: ${vertical.l};
  margin-bottom: ${vertical.l};
  ${column({
    widthMobile: 4,
    widthTablet: 3,
    widthDesktop: 6,
  })}
`

const ExpertImage = styled.img`
  /* width: 96px / 2.4 = 40px
   * height 104px / 2.4 = calc(104px / 2.4) but the right hand side of a division must be an integer
   */
  height: calc(1040px / 24);
  width: 40px;

  :not(:last-of-type) {
    margin-right: ${horizontal.xs};
  }

  ${breakpoints.tablet`
    height: calc(104px / 3);
    width: 32px;
  `}

  ${breakpoints.desktop`
    height: 52px;
    width: 48px;

    :not(:last-of-type) {
      margin-right: ${horizontal.s};
    }
  `}
`

const StoryAndExperts: React.FC = () => {
  const randomExperts = React.useMemo(() => randomExpertImageLinks(3), [])

  return (
    <Container>
      <GridRow
        css={css`
          display: flex;
          flex-direction: column;

          ${breakpoints.tablet`
          flex-direction: row;
        `}
        `}
      >
        <Section
          css={css`
            ${breakpoints.tablet`
            padding-right: ${horizontal.l};
          `}

            ${breakpoints.desktop`
            padding-right: ${horizontal.xxl};
          `}
          `}
        >
          <Headline2
            css={css`
              margin-bottom: ${vertical.xs};
            `}
          >
            Our story
          </Headline2>
          <Body
            css={css`
              margin-bottom: ${vertical.m};
            `}
          >
            Back in 2016, we created Habito to end mortgage hell forever. No
            more paperwork, jargon or misinformation. No more stress. It all
            started when our founder Dan tried to buy a house… and it all went a
            bit wrong.
          </Body>
          <TertiaryLink
            text="How it all began: read more"
            href="https://medium.com/hey-habito/hey-habito-5435bdc44ba7"
            onClick={() => {
              logClick("how_it_all_began")
            }}
            icon={{
              kind: "right",
              icon: <ChevronRightIcon />,
            }}
            width="content-width"
          />
        </Section>
        <Section
          css={css`
            color: ${colours.white};

            ${breakpoints.tablet`
            padding-left: ${horizontal.l};
          `}

            ${breakpoints.desktop`
            padding-left: ${horizontal.xxl};
          `}
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: ${vertical.xs};
            `}
          >
            <Headline2>Our experts</Headline2>
            <div>
              {randomExperts.map((expert, index) => (
                <ExpertImage key={`ME-${index}`} src={expert} alt="" />
              ))}
            </div>
          </div>
          <Body>
            With Habito, you’ll have people by your side the whole way. They’ll
            take the stress off your shoulders and give you hours of your life
            back.
          </Body>
          <Body>
            Our team of amazing brokers will find you the best mortgage, and
            with our{" "}
            <TextLink
              text="complete home-buying service"
              href="/home-buying"
              onClick={() => {
                logClick("complete_home-buying_service")
              }}
              colour="white"
            />
            , you’ll have the support of our surveyor and conveyancer partners
            too.
          </Body>
        </Section>
      </GridRow>
    </Container>
  )
}

export default StoryAndExperts
