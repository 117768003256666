import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { logClick } from "../amplitude"

import {
  breakpoints,
  Card,
  gridSettings,
  mkColumn,
  mkSubGridRow,
  vertical,
  CircleArrow,
} from "design-kit"

import journeyItems from "./journeyItems"
import Timeline from "./Timeline"

const subGridSettings = {
  desktop: {
    ...gridSettings.desktop,
    maxColumns: 8,
  },
  tablet: gridSettings.tablet,
  mobile: gridSettings.mobile,
}

const SubGridRow = mkSubGridRow(breakpoints)(subGridSettings)
const subColumn = mkColumn(breakpoints)(subGridSettings)

const ReviewCard = styled(Card)`
  height: 256px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.tablet`
    height: 208px;
  `}

  ${breakpoints.tablet`
    height: 236px;
  `}
`

const ArrowButtonContainer_ = styled.div`
  ${subColumn({
    widthMobile: 2,
    widthTablet: 3,
    widthDesktop: 1,
  })}
  display: flex;
  align-items: center;
  margin-top: ${vertical.xs};
`

const LeftArrowButtonContainer = styled(ArrowButtonContainer_)`
  justify-content: flex-start;

  ${breakpoints.desktop`
    order: -1;
  `}
`

const RightArrowButtonContainer = styled(ArrowButtonContainer_)`
  justify-content: flex-end;
`

export const JourneyCards: React.FC = () => {
  const [currentIdx, setCurrentIdx] = useState<number>(0)

  const currentReview = journeyItems[currentIdx].card
  const previousPreviousYear =
    journeyItems[currentIdx - 2] && journeyItems[currentIdx - 2].year
  const previousYear =
    journeyItems[currentIdx - 1] && journeyItems[currentIdx - 1].year
  const currentYear = journeyItems[currentIdx].year
  const nextYear =
    journeyItems[currentIdx + 1] && journeyItems[currentIdx + 1].year
  const nextNextYear =
    journeyItems[currentIdx + 2] && journeyItems[currentIdx + 2].year
  const journeyLength = journeyItems.length

  return (
    <SubGridRow>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          ${subColumn({
            widthMobile: 4,
            widthTablet: 6,
            widthDesktop: 6,
          })}
        `}
      >
        <ReviewCard element="div">{currentReview}</ReviewCard>
        <Timeline
          i={currentIdx}
          journeyLength={journeyLength}
          previousPreviousYear={previousPreviousYear}
          previousYear={previousYear}
          year={currentYear}
          nextYear={nextYear}
          nextNextYear={nextNextYear}
        />
      </div>

      <LeftArrowButtonContainer>
        <CircleArrow
          arrowColour="black"
          filled={true}
          size="large"
          direction="left"
          aria-label="Previous review"
          onClick={() => {
            if (currentIdx > 0) {
              setCurrentIdx(currentIdx - 1)
            }
            logClick("our_journey_so_far_previous")
          }}
          disabled={currentIdx === 0}
        />
      </LeftArrowButtonContainer>

      <RightArrowButtonContainer>
        <CircleArrow
          arrowColour="black"
          filled={true}
          size="large"
          direction="right"
          aria-label="Next review"
          onClick={() => {
            if (currentIdx < journeyItems.length - 1) {
              setCurrentIdx(currentIdx + 1)
            }
            logClick("our_journey_so_far_next")
          }}
          disabled={currentIdx === journeyItems.length - 1}
        />
      </RightArrowButtonContainer>
    </SubGridRow>
  )
}
