import { SerializedStyles } from "@emotion/react"
import React from "react"
import { media } from ".."

type AllOrNone<T> = T | { [K in keyof T]?: never }

type Props = {
  srcDefault: string
  srcWebP: string
  srcRetina: string
  srcRetinaWebP: string
  imageType: string
  alt: string
  loading?: "eager" | "lazy"
  imgCss?: SerializedStyles
} & AllOrNone<AnimatedProps>

type AnimatedProps = {
  srcWebPMotion: string
  srcMotion: string
  motionImageType: string
}

export const ResponsiveImage: React.FC<Props> = ({
  srcDefault,
  srcWebP,
  srcRetina,
  srcRetinaWebP,
  imageType,
  alt,
  loading,
  imgCss,
  srcWebPMotion,
  srcMotion,
  motionImageType,
}) => (
  <picture>
    {srcWebPMotion && (
      <React.Fragment>
        <source
          media={media.allowsMotion.query}
          srcSet={srcWebPMotion}
          type="image/webp"
        />
        <source
          media={media.allowsMotion.query}
          srcSet={srcMotion}
          type={motionImageType}
        />
      </React.Fragment>
    )}
    <source
      media={media.retinaScreen.query}
      srcSet={srcRetinaWebP}
      type="image/webp"
    />
    <source
      media={media.retinaScreen.query}
      srcSet={srcRetina}
      type={imageType}
    />
    <source srcSet={srcWebP} type="image/webp" />
    <img css={imgCss} src={srcDefault} alt={alt} loading={loading} />
  </picture>
)
