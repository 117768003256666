import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { logClick } from "./amplitude"

import {
  ArrowRightThickIcon,
  Body,
  breakpoints,
  colours,
  column,
  GridRow,
  Headline2,
  media,
  PrimaryTwoLink,
  vertical,
} from "design-kit"

import bgMobile from "./assets/joinUs/mobile-action-1x.jpg"
import bgTablet from "./assets/joinUs/tablet-action-1x.jpg"
import bgDesktop from "./assets/joinUs/desktop-action-1x.jpg"
import bgMobile2x from "./assets/joinUs/mobile-action-2x.jpg"
import bgTablet2x from "./assets/joinUs/tablet-action-2x.jpg"
import bgDesktop2x from "./assets/joinUs/desktop-action-2x.jpg"

const FALLBACK_GRADIENT = `linear-gradient(
  135deg,
  ${colours.gradientBase.bubblegum},
  ${colours.gradientBase.seafoam}
)`

const Section = styled.section`
  color: ${colours.offBlack};
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${vertical.l} 0;

  background-color: ${colours.gradientBase.bubblegum};
  background-image: url("${bgMobile}");
  background-image: url("${bgMobile}"), ${FALLBACK_GRADIENT};
  background-repeat: no-repeat;
  background-size: cover;
  ${media.retinaScreen`
    background-image: url("${bgMobile2x}");
    background-image: url("${bgMobile2x}"), ${FALLBACK_GRADIENT};
  `}

  ${breakpoints.tablet`
    background-image: url("${bgTablet}");
    background-image: url("${bgTablet}"), ${FALLBACK_GRADIENT};
    ${media.retinaScreen`
      background-image: url("${bgTablet2x}");
      background-image: url("${bgTablet2x}"), ${FALLBACK_GRADIENT};
    `}
  `}

  ${breakpoints.desktop`
    padding: ${vertical.xl} 0;

    background-image: url("${bgDesktop}");
    background-image: url("${bgDesktop}"), ${FALLBACK_GRADIENT};
    ${media.retinaScreen`
      background-image: url("${bgDesktop2x}");
      background-image: url("${bgDesktop2x}"), ${FALLBACK_GRADIENT};
    `}
  `}
`

const Title = styled(Headline2)`
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.s};
  `}
`

const JoinUs: React.FunctionComponent = () => (
  <Section>
    <GridRow>
      <div
        css={css`
          ${column({
            widthMobile: 4,
            widthTablet: 6,
            widthDesktop: 12,
          })}
        `}
      >
        <Title>Join us!</Title>
        <Body
          css={css`
            margin-bottom: ${vertical.m};

            ${breakpoints.desktop`
              margin-bottom: ${vertical.l};
            `}
          `}
        >
          Help us in our mission to help people find home
        </Body>
        <PrimaryTwoLink
          text="See our jobs"
          href="/careers"
          onClick={() => {
            logClick("see_our_jobs")
          }}
          icon={{
            kind: "right",
            icon: <ArrowRightThickIcon color="currentColor" />,
          }}
          width={{
            mobile: "full-width",
            tablet: "content-width",
            desktop: "content-width",
          }}
        />
      </div>
    </GridRow>
  </Section>
)

export default JoinUs
