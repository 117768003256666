import React from "react"

import styled from "@emotion/styled"

import { breakpoints } from "../index"
import { horizontal, vertical } from "../variables/layout/Spacing"
import { useMediaQuery } from "../variables/layout/Media"

import { BodyBold } from "./typography/Typography"
import { CircleArrow } from "./CircleArrow"

type Direction = "vertical" | "horizontal"
interface Props {
  onClick: () => void
  text: string
  direction?: Direction
}

const Box = styled.div<{ direction: Direction }>`
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: ${({ direction }) =>
    direction === "horizontal" ? "row" : "column"};
`

const Text = styled(BodyBold)<{ direction: Direction }>`
  margin-left: ${({ direction }) =>
    direction === "horizontal" ? horizontal.s : 0};

  margin-top: ${({ direction }) =>
    direction === "vertical" ? vertical.xs : 0};

  ${({ direction }) => direction === "horizontal" && "margin-bottom: 0;"}
`

export const ScrollTo: React.FunctionComponent<Props> = ({
  onClick,
  text,
  direction = "vertical",
}) => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  return (
    <Box direction={direction}>
      <CircleArrow
        direction="down"
        arrowColour="black"
        filled={false}
        size={isDesktop ? "large" : "small"}
        aria-label={`Scroll to ${text}`}
        onClick={onClick}
      />
      <Text aria-hidden="true" direction={direction}>
        {text}
      </Text>
    </Box>
  )
}
