import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { logClick } from "../amplitude"

import {
  GridRow,
  Headline,
  Statement1,
  breakpoints,
  colours,
  column,
  media,
  mixins,
  useHasMounted,
  useMediaQuery,
  vertical,
  ScrollTo,
} from "design-kit"

import { cloud1, cloud2 } from "./Clouds"
import InfoCards from "./InfoCards"

import heroMobile from "../../../assets/backgrounds/hero-sky-b/mobile_BG.jpg"
import heroTablet from "../../../assets/backgrounds/hero-sky-b/tablet_BG.jpg"
import heroDesktop from "../../../assets/backgrounds/hero-sky-b/desktop_BG.jpg"
import heroMobile2x from "../../../assets/backgrounds/hero-sky-b/mobile_BG_retina.jpg"
import heroTablet2x from "../../../assets/backgrounds/hero-sky-b/tablet_BG_retina.jpg"
import heroDesktop2x from "../../../assets/backgrounds/hero-sky-b/desktop_BG_retina.jpg"
import key from "../assets/hero/key/tablet-key.png"
import keyWebp from "../assets/hero/key/tablet-key.webp"
import keyDesktop from "../assets/hero/key/desktop-key.png"
import keyDesktopWebp from "../assets/hero/key/desktop-key.webp"

const FALLBACK_GRADIENT = `linear-gradient(
  190deg,
  ${colours.gradientBase.mistyViolet} 75%,
  ${colours.gradientBase.seafoam}
)`

const Container = styled.div`
  position: relative;
  color: ${colours.offBlack};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  ${mixins.gradientBackground({
    fallbackColor: colours.gradientBase.mistyViolet,
    mobileFallbackGradient: FALLBACK_GRADIENT,
    mobileBackgroundImage: heroMobile,
    mobileBackgroundImageRetina: heroMobile2x,
    tabletFallbackGradient: FALLBACK_GRADIENT,
    tabletBackgroundImage: heroTablet,
    tabletBackgroundImageRetina: heroTablet2x,
    desktopFallbackGradient: FALLBACK_GRADIENT,
    desktopBackgroundImage: heroDesktop,
    desktopBackgroundImageRetina: heroDesktop2x,
  })}
`

const ScrollRef = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`
const Heading = styled(Statement1)`
  position: relative;
  z-index: 1;
  margin-top: calc(${vertical.xl} + ${vertical.xl});
  margin-bottom: ${vertical.m};

  ${breakpoints.desktop`
    margin-top: calc(${vertical.xxl} + ${vertical.xxl});
    margin-bottom: ${vertical.l};
  `}
`

const ScrollToBox = styled.div`
  display: none;

  ${breakpoints.desktop`
    display: block;
    margin-bottom: ${vertical.xxl};
  `};
`

const HeroAndServices: React.FunctionComponent = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const allowAnimation = useMediaQuery(media.allowsMotion.query)
  const hasMounted = useHasMounted()

  const scrollTo = (): void => {
    const considerateScrollBehavior = allowAnimation ? "smooth" : "auto"
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: considerateScrollBehavior,
      })
    }
    logClick("how_we_can_help_you")
  }

  return (
    <Container>
      {cloud1}
      {cloud2}
      <GridRow>
        <div
          css={css`
            ${column({
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 12,
            })}
          `}
        >
          <div
            css={css`
              ${column({
                widthMobile: 4,
                widthTablet: 4,
                widthDesktop: 10,
                offsetTablet: 1,
                offsetDesktop: 1,
              })}
            `}
          >
            <div
              css={css`
                position: relative;
              `}
            >
              <Heading>Our mission is to help people find home</Heading>
              {isDesktop ? (
                <picture
                  key={`${hasMounted}`}
                  css={css`
                    position: absolute;
                    top: 0;
                    right: -160px;
                  `}
                >
                  <source srcSet={keyDesktopWebp} type="image/webp" />
                  <img src={keyDesktop} alt="" />
                </picture>
              ) : (
                <picture
                  css={css`
                    position: absolute;
                    transform: scale(0.7);
                    top: -120px;
                    right: -90px;

                    ${breakpoints.tablet`
                      transform: unset;
                      top: 32px;
                      right: -180px;
                    `}
                  `}
                >
                  <source srcSet={keyWebp} type="image/webp" />
                  <img src={key} alt="" />
                </picture>
              )}
            </div>
            <Headline
              css={css`
                margin-bottom: ${vertical.xl};
                position: relative;
                z-index: 1;

                ${breakpoints.desktop`
                  padding-left: ${vertical.xxl};
                  max-width: 70%;
                `}
              `}
            >
              For far too many people, buying a home and sorting a mortgage is
              disempowering and confusing. We’re here to fix that. We give
              people the tools, knowledge and expert support they need to help
              them buy and finance their homes.
            </Headline>
          </div>
          <ScrollToBox>
            <ScrollTo onClick={scrollTo} text="How we can help you" />
          </ScrollToBox>
          <ScrollRef ref={scrollRef} />
        </div>
        <InfoCards />
      </GridRow>
    </Container>
  )
}

export default HeroAndServices
