import React from "react"
import { css } from "@emotion/react"

import { breakpoints, ResponsiveImage } from "design-kit"

import cloud1Png from "../assets/hero/clouds/cloud1-1x.png"
import cloud1Webp from "../assets/hero/clouds/cloud1-1x.webp"
import cloud1Retina from "../assets/hero/clouds/cloud1-2x.png"
import cloud1RetinaWebp from "../assets/hero/clouds/cloud1-2x.webp"

import cloud2Png from "../assets/hero/clouds/cloud2-1x.png"
import cloud2Webp from "../assets/hero/clouds/cloud2-1x.webp"
import cloud2Retina from "../assets/hero/clouds/cloud2-2x.png"
import cloud2RetinaWebp from "../assets/hero/clouds/cloud2-2x.webp"

export const cloud1: React.ReactElement = (
  <ResponsiveImage
    srcRetinaWebP={cloud1RetinaWebp}
    srcRetina={cloud1Retina}
    srcWebP={cloud1Webp}
    srcDefault={cloud1Png}
    imageType="image/png"
    alt=""
    imgCss={css`
      width: 1047px;
      display: none;

      ${breakpoints.desktop`
        display: block;
        position: absolute;
        top: 600px;
        right: -280px;
      `}
    `}
  />
)

export const cloud2: React.ReactElement = (
  <ResponsiveImage
    srcRetinaWebP={cloud2RetinaWebp}
    srcRetina={cloud2Retina}
    srcWebP={cloud2Webp}
    srcDefault={cloud2Png}
    imageType="image/png"
    alt=""
    imgCss={css`
      width: 862px;
      display: none;

      ${breakpoints.desktop`
        display: block;
        position: absolute;
        top: 1000px;
        left: -360px;
      `}
    `}
  />
)
