import React from "react"
import styled from "@emotion/styled"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import HeroAndServices from "../views/AboutUs/HeroAndServices"
import StoryAndExperts from "../views/AboutUs/StoryAndExperts"
import OurJourney from "../views/AboutUs/OurJourney"
import JoinUs from "../views/AboutUs/JoinUs"

import config from "../../config.json"

const pageName = "about_us"

const AboutUsPage: React.FC = () => (
  <WrappedBase
    metaTitle="About us | Habito"
    metaDescription="Learn about our story here at Habito, and how we came to be the UK's leading digital mortgage broker."
    canonicalUrl="https://www.habito.com/about-us"
    noIndex={false}
    pageName={pageName}
    intercom={false}
    config={config}
    head={null}
  >
    <Content />
  </WrappedBase>
)

const PageContainer = styled.div`
  overflow: hidden;

  > header {
    margin-top: 0;
  }
`

const Content: React.FC = () => (
  <PageContainer>
    <Navbar theme="transparent-dark" />
    <main id="main-content">
      <HeroAndServices />
      <StoryAndExperts />
      <OurJourney />
      <JoinUs />
    </main>
    <Footer />
  </PageContainer>
)

export default AboutUsPage
