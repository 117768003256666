import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  colours,
  vertical,
  GridRow,
  column,
  typographyStyles,
} from "design-kit"

import { JourneyCards } from "./JourneyCards"

const Section = styled.section`
  background-color: ${colours.constructive.main};
  color: ${colours.offBlack};
  padding-top: ${vertical.xl};
  padding-bottom: ${vertical.xl};
`

const SectionTitle = styled.h2`
  ${typographyStyles.headline1};

  ${breakpoints.desktop`
    ${typographyStyles.statement2}
  `}
`

const OurJourney: React.FC = () => (
  <Section>
    <GridRow>
      <SectionTitle
        css={css`
          ${column({
            widthMobile: 4,
            widthTablet: 6,
            widthDesktop: 4,
          })}
          margin-bottom: ${vertical.s};
        `}
      >
        Our{" "}
        <span
          css={css`
            ${breakpoints.desktop`
              display: table;
            `}
          `}
        >
          journey
        </span>{" "}
        so far
      </SectionTitle>

      <div
        css={css`
          ${column({
            widthMobile: 4,
            widthTablet: 6,
            widthDesktop: 8,
          })}
        `}
      >
        <JourneyCards />
      </div>
    </GridRow>
  </Section>
)

export default OurJourney
