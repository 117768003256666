// ## Updating the images

// Two S3 buckets are used in this process `assets.habito.com/expert-avatars` and
// `assets.habito.com/expert-avatars-test`. The latter is a temporary area where
// we point the app to while the changes are deploying. This is so that we don't
// reference unavailable/incorrect file paths.

// The steps in this process are:
//   1. Add/remove the image from `./images/`, update `numberOfAvatars` and/or the mapping of id to name in the comments below if they have changed
//   2. Update `./src/index.ts` so that the paths match those referenced in `./images/`
//   3. Run `aws s3 sync images s3://assets.habito.com/expert-avatars-test` (needs `okta-aws-cli`,
//   see https://doc.habito.com/.aws/README.html for more info)
//   4. Push your changes and check they are live and working as expected
//   5. Update `./src/index.ts` to point back to the `assets.habito.com/expert-avatars` bucket
//   6. Run `aws s3 sync images s3://assets.habito.com/expert-avatars`
//   7. Check everything works locally and deploy these changes

// 01 Ellen
// 02 Tom
// 03 Matthew
// 04 Tuki
// 05 Antra
// 06 Nicola
// 07 Pietro
// 08 Richard
// 09 Will

const bucket = "https://assets.habito.com/expert-avatars/"

const numberOfAvatars = 9

/**
 * Exported for Design Kit documentation purposes only.
 */
export const expertAvatarIds: string[] = []
for (let i = 1; i <= numberOfAvatars; i++) {
  expertAvatarIds.push(i.toLocaleString("en-GB", { minimumIntegerDigits: 2 }))
}

// the Fisher-Yates shuffle
const shuffle = (arr: string[]): string[] => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }
  return arr
}

/**
 * S3 image links for the expert images.
 */
export const expertImageLinks: string[] = expertAvatarIds.map(
  id => bucket + "expert-" + id + ".png"
)

/**
 * Returns a random sample of S3 image links.
 */
export const randomExpertImageLinks = (n: number): string[] => {
  return shuffle(expertImageLinks).slice(0, n)
}
